
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'
import {EscapeShellStringsTool} from 'components/EscapeShellStringsTool'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Anche tu perdi troppo tempo fra `}<em parentName="p">{`single quote`}</em>{`, `}<em parentName="p">{`double quote`}</em>{` e `}<em parentName="p">{`backslash`}</em>{` negli `}<strong parentName="p">{`argomenti shell`}</strong>{`?`}</p>
    <p>{`Usa questo semplice strumentino per fare l’`}<em parentName="p">{`escape`}</em>{` di stringhe in comandi shell.`}</p>
    <EscapeShellStringsTool mdxType="EscapeShellStringsTool" />

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    